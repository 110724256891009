import React from 'react'

export const statesData = {
  label: 'Estado',
  placeholder: 'Selecione o Estado',
  message: 'Selecione o Estado',
  items: [
  {
    value: 'SP',
    text: "São Paulo",
  },
  {
    value: "others",
    text: "Outros",
  },
]}
export const statesContraction={
  label: 'Estado',
  placeholder: 'Selecione',
  message: 'Selecione',
  items: [
  {
    value: "sp",
    text: "SP",
  },
  {
    value: 'pr',
    text: "PR",
  },
  {
    value: 'rj',
    text: "RJ",
  }
  ]

}
export const convenio ={
  label: 'Aceita convênios?',
  placeholder: 'Selecione',
  message: 'Selecione',
  items: [
  {
    value: 1,
    text: "Sim",
  },
  {
    value: 2,
    text: "Não",
  }
  ]
}
export const acessibilidade ={
  label: 'Tem acessibilidade?',
  placeholder: 'Selecione',
  message: 'selecione',
  items: [
  {
    value: 1,
    text: "Sim",
  },
  {
    value: 2,
    text: "Não",
  }
  ]
}

export const satates = {
  label: 'Estado',
  placeholder: 'Selecione seu Estado',
  message: 'Selecione um Estado',
  items: [
  {
    value: 'SP',
    text: "São Paulo",
  },
  {
    value: 'RJ',
    text: "Rio de janeiro",
  },
  {
    value: 'PE',
    text: "Pernambuco",
  },
  {
    value: "others",
    text: "Outros",
  },
]}
export const YesOrNo =[
  {

    
  }
]
export const jobsData = {
  label: 'Profissão',
  placeholder: 'Selecione sua Profissão',
  message: 'Selecione sua Profissão',
  items: [
    {
      value: 1,
      text: "Enfermagem",
    },
    {
      value: 2,
      text: "Fisioterapia",
    },
    {
      value: 3,
      text: "Fonoaudiologia",
    },
    {
      value: 4,
      text: "Medicina",
    },
    {
      value: 5,
      text: "Nutrição",
    },
    {
      value: 6,
      text: "Odontologia",
    },
    {
      value: 7,
      text: "Psicologia",
    },
  ]
}
export const expertiseData = {
  label: 'Especialidades',
  placeholder: 'Selecione ',
  message: 'Selecione sua Especialidade',
  items: [
    {
      value: 1,
      text: "Cardiologista",
    },
    {
      value: 2,
      text: "Nefrologista",
    },
    {
      value: 3,
      text: "neurologista",
    },
    {
      value: 4,
      text: "Dermatologista",
    },
    
  ]
}
export const  Presencial= [
	  { key: "1", value: "Atendimento Presencial" },
]
export const  Online = [
    { key: "2", value: "Atendimento Online" },
]
export const  Repetir = [
  { key: "3", value: "Repetir horários do atendimento presencial" },
]

export const dayOfTheWeek = {
  label: 'Dia da semana',
  placeholder: 'Selecione ',
  items: [
    {
      value: 2,
      text: "Segunda",
    },
    {
      value: 3,
      text: "Terça",
    },
    {
      value: 4,
      text: "Quarta",
    },
    {
      value: 5,
      text: "Quinta",
    },
    {
      value: 6,
      text: "Sexta",
    },
    
  ]
}
export const diversityData = [
  {
    type: "radio",
    title: "Pronome",
    key: "pronome",
    question: "Com qual pronome você se identifica?",
    options: [
      { key: "1", value: "a/Ela/Dela" },
      { key: "2", value: "o/Ele/Dele" },
      { key: "3", value: "e/Elu/Delu" },
      { key: "4", value: "Outro" },
    ],
    labelOtherInputs: 'Se você marcou a opção "outro", pode nos contar com qual pronome você se identifica?',
    otherInputs: [
      {placeholder: "Digite o seu artigo", example: ['Exemplo de artigo: "', <span className="subtext">a</span>, ' Eduarda foi à padaria"']},
      {placeholder: "Digite o seu pronome", example: ['Exemplo de pronome: "', <span className="subtext">ela</span>, ' gosta de funk"']}
    ]
  },
  {
    type: "radio",
    title: "Etnia",
    key: "etnia",
    question: "Com qual etnia você se identifica?",
    options: [
      { key: "1", value: "Amarela" },
      { key: "2", value: "Branca" },
      { key: "3", value: "Indígena" },
      { key: "4", value: "Parda" },
      { key: "5", value: "Preta" },
      { key: "6", value: "Outra" },
    ],
    labelOtherInputs: 'Se você marcou a opção "outra", pode nos contar com qual etnia você se identifica?',
    otherInputs: [
      {placeholder: "Digite a sua etnia"}
    ]
  },
  {
    type: "radio",
    title: "Gênero",
    key: "genero",
    question: "Qual é a sua identidade de gênero?",
    options: [
      { key: "1", value: "Agênero" },
      { key: "2", value: "Fluida" },
      { key: "3", value: "Homem Cis" },
      { key: "4", value: "Homem Trans" },
      { key: "5", value: "Intersexo" },
      { key: "6", value: "Mulher Cis" },
      { key: "7", value: "Mulher Trans" },
      { key: "8", value: "Não-Binária" },
      { key: "9", value: "Travesti" },
      { key: "10", value: "Outra" },
    ],
    labelOtherInputs: 'Se você marcou a opção "outra", pode nos contar qual é a sua identidade de gênero?',
    otherInputs: [
      {placeholder: "Digite a sua identidade de gênero"}
    ]
  },
  {
    type: "radio",
    title: "Sexualidade",
    key: "sexualidade",
    question: "Qual é a sua sexualidade?",
    options: [
      { key: "1", value: "Assexual" },
      { key: "2", value: "Bissexual" },
      { key: "3", value: "Demissexual" },
      { key: "4", value: "Gay" },
      { key: "5", value: "Heterossexual" },
      { key: "6", value: "Pansexual" },
      { key: "7", value: "Lésbica" },
      { key: "8", value: "Outra" },
    ],
    labelOtherInputs: 'Se você marcou a opção "outra", pode nos contar qual é a sua sexualidade?',
    otherInputs: [
      {placeholder: "Digite a sua sexualidade"}
    ]
  },
  {
    type: "check",
    title: "Deficiência",
    key: "deficiencia",
    question: "Você possui alguma deficiência?",
    options: [
      { key: "1", value: "Não possuo deficiência" },
      { key: "2", value: "Auditiva" },
      { key: "3", value: "Cognitiva" },
      { key: "4", value: "Motora" },
      { key: "5", value: "Múltipla" },
      { key: "6", value: "Visual" },
      { key: "7", value: "Outra" },
    ],
    labelOtherInputs: 'Se você marcou a opção "outra", pode nos contar qual deficiência você tem?',
    otherInputs: [
      {placeholder: "Digite a sua deficiência"}
    ]
  },
]
