import React, { useCallback, useState } from "react"
import styled from "styled-components"
import Proimg from "../../assets/images/profissional-saude2.svg"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import Header from "../../components/profissionaisSaude/Header"
import { Dropdown } from "../../components/profissionaisSaude/Inputs/Dropdown"
import { statesData } from "../../utils"
import { isEmptyString } from "../../functions/isEmptyString"
import Footer from "../../components/profissionaisSaude/Footer"
import colors from "../../styles/tokens/colors"

const Container = styled.div`
  padding: 0 6.875rem;

  section {
    width: 100%;
    max-width: 68.75rem;
    min-height: calc(100vh - 13.625rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    gap: 2rem;
  }

  .form-area {
    width: 100%;
    max-width: 27.5rem;
    height: 22rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;

  }

  .img-side {
    display: flex;
    flex-direction: column;
    width: 40rem;
    height: 27rem;
    align-items: flex-end;
    padding-bottom: 0.5rem;
  }

  .image-credits {
    color: #515151;
    font-size: 0.75rem;
    align-self: flex-end;
    white-space: nowrap;
    .anchor-image {
      cursor: pointer;
      color: ${colors.gray.dark};
      text-decoration: underline;
    }
  }
  .empty{
    height: 450px;
  }

  @media screen and (max-width: 700px) {
    padding: 4rem 2.5rem;
    .img-side {
      display: none;
    }
  }
`

export default function CriarConta() {
  const [selectedValue, setSelectedValue] = useState("")
  const [isSelectActive, setIsSelectActive] = useState(false)
  const [isError, setIsError] = useState(null)

  function handleSelectedOption(value) {
    setSelectedValue(value)
    setIsSelectActive(false)
   
  }

  const handleOpenSelect = useCallback(() => {
    setIsSelectActive(!isSelectActive)
    setIsError(isEmptyString(selectedValue) ? "Este campo é obrigatório" : null)
  }, [selectedValue, isSelectActive])

  const handleOnSubmit = useCallback(
    e => {
      const invalid = isEmptyString(selectedValue)

      if (invalid) e.preventDefault()

      setIsError("Este campo é obrigatório")
    },
    [selectedValue]
  )

  return (
    <>
      <Header hasVoltar />
      <Container>
        <section>
          
            <div className="form-area">
              <h1>
                Informe o Estado onde você atua
              </h1>
              <Dropdown
                data={statesData}
                selectedValue={selectedValue}
                onSelectOption={handleSelectedOption}
                isSelectActive={isSelectActive}
                onOpenSelect={handleOpenSelect}
                onChange={(event)=>setSelectedValue(event.target.value)}
                isError={isError}
                isOverflowed={true}
              />
              <Button
                link={selectedValue === "São Paulo" ? "/saude/cadastro" : "/saude/localidade-indisponivel/"}
                title="Próximo"
                onClick={handleOnSubmit}
                disabled={!selectedValue}
              />
            </div>
          
          <div className="img-side">
            <Proimg alt="Descrição da imagem" />
            <span className="image-credits">
              Ilustrado por{" "}
              <a className="anchor-image" href="https://br.freepik.com/">
                Freepik
              </a>
            </span>
          </div>
        </section>
        <Footer />
      </Container>
    </>
  )
}
