import React from "react"
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp"
import Check from "@mui/icons-material/CheckSharp"
import CancelIcon from "@mui/icons-material/Cancel"

import { Container, ErrorBlock, Overlay } from "./styles"
import { useRandomIds } from "../../../../functions/useRandomIds"

export function Dropdown({
  data,
  isDisabled = false,
  isError,
  selectedValue,
  onSelectOption,
  onOpenSelect,
  isSelectActive,
  large = false,
  isOverflowed = false,
}) {
  const id = useRandomIds()

  return (
    <>
      <Container large={large}>
        <label id={id("label")} htmlFor={id("dropdown")} large={large}>
          {data.label}
        </label>
        <button
          className={
            (isSelectActive && "actived") ||
            (isDisabled && "disabled") ||
            (isError && !selectedValue && "error") ||
            (selectedValue !== "" && "fill")
          }
          onClick={!isDisabled ? onOpenSelect : () => {}}
          role="combobox"
          tabIndex={0}
          aria-controls={id("options")}
          aria-owns={id("options")}
          aria-expanded={isSelectActive}
          aria-haspopup="listbox"
          aria-required={true}
          aria-invalid={isError && !selectedValue && !isSelectActive}
          id={id("dropdown")}
        >
          {!selectedValue || isSelectActive ? (
            <p className="placeholder">{data.placeholder}</p>
          ) : (
            <p aria-labelledby={id("label")}>{selectedValue}</p>
          )}

          <span>
            {isError && !selectedValue && !isSelectActive && <CancelIcon />}
            <KeyboardArrowDownSharpIcon
              size={18}
              titleAccess="Abre menu de opções"
              htmlColor="#CFCFCF"
            />
          </span>
        </button>
        {isSelectActive && (
          <ul className={isOverflowed ? "overflowed": "" } id={id("options")} aria-labelledby={id("label")} role="listbox">
            {data &&
              data.items.map(option => (
                <li key={option.value}>
                  <button
                    role="option"
                    tabIndex={0}
                    onClick={() => onSelectOption(option.text)}
                    onKeyDown={event => {
                      if (["Space", "Enter"].includes(event.code)) {
                        onSelectOption(option.text)
                      }
                    }}
                    aria-selected={selectedValue === option.text}
                    id={`option-${option.value}`}
                    className={
                      selectedValue === option.text && "actived-option"
                    }
                  >
                    {option.text}
                    {selectedValue === option.text && <Check size={18} />}
                  </button>
                </li>
              ))}
          </ul>
        )}
        {isError && !selectedValue && !isSelectActive && (
          <ErrorBlock>{isError}</ErrorBlock>
        )}
      </Container>
      {isSelectActive && <Overlay onClick={onOpenSelect} />}
    </>
  )
}
