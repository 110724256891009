import { useState } from "react"

/**
 * Gera uma sequência aleatória de 16 letras
 * @returns {string}
 */
export const randomId = () => {
  return Array.from({ length: 16 })
    .map(_ =>
      Math.floor(Math.random() * 255 + 0)
        .toString(16)
        .padStart(2, "0")
    )
    .join("")
}

export const useRandomIds = () => {
  const [ids, setIds] = useState({})

  return key =>
    key in ids ? ids[key] : setIds(oldIds => ({ ...oldIds, [key]: `${key}:${randomId()}` }))
}
