import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    font: ${({ theme }) => theme.text.textBaseHighlight};
    color: ${({ theme }) => theme.colors.black.main};
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  > button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    background-color: ${({ theme }) => theme.colors.white.main};
    border: 1px solid ${({ theme }) => theme.colors.gray.mid};
    height: 3rem;
    font-size: 1rem;
    padding: 0 0.75rem;
    cursor: pointer;

    span {
      display: flex;
      gap: 0.5rem;
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.green["700"]};
    }

    &.error {
      border: 1px solid ${({ theme }) => theme.colors.error};
    }
  }

  .actived {
    border: 2px solid ${({ theme }) => theme.colors.green["900"]};
    z-index: 5;
  }

  .disabled {
    background-color: ${({ theme }) => theme.colors.gray.disable};
    border: 1px solid ${({ theme }) => theme.colors.text.disable};
    cursor: not-allowed;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.text.disable};
    }
  }

  .actived-option {
    background-color: ${({ theme }) => theme.colors.green["100"]};
    font-weight: bold;
  }

  .placeholder {
    color: ${({ theme }) => theme.colors.gray.disable};
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeBase};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.bold};
  }

  ul {
    width: 100%;
    position: absolute;
    z-index: 9;
    top: 5.125rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: ${({ theme }) => theme.colors.white.main};
    border-radius: 0.5rem;
    padding-top: 0.5rem;

    &.overflowed{
      height: 7.625rem;
      position: revert;
      overflow-y: auto;
    }

    li button {
      padding: 0.75rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      width: 100%;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray.disable};
      }
    }
  }

  .error {
    border: 1px solid ${({ theme }) => theme.colors.error.main};

    svg {
      color: ${({ theme }) => theme.colors.error.main};
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.error.main};
    }
  }

  .fill {
    border-color: ${({ theme }) => theme.colors.secondary.health_green};
  }

  span {
    color: ${({ theme }) => theme.colors.auxiliary.alert};
  }
`

export const Overlay = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100%;
  z-index: 1;
`

export const ErrorBlock = styled.span`
  margin-top: 0.6875rem;
`
